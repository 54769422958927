//
// Carousel styles
//

.carousel-control-prev-icon {
  &::after {
    content: '\f053';
    --#{$prefix}carousel-control-icon-font-size: #{$carousel-control-icon-font-size};
    --#{$prefix}carousel-control-icon-font-weight: #{$font-weight-bold};

    font-weight: var(--#{$prefix}carousel-control-icon-font-weight);
    font-size: var(--#{$prefix}carousel-control-icon-font-size);
    font-family: 'Font Awesome 6 Pro', 'Font Awesome 6 Free';
  }
}
.carousel-control-next-icon {
  &::after {
    content: '\f054';
    --#{$prefix}carousel-control-icon-font-size: #{$carousel-control-icon-font-size};
    --#{$prefix}carousel-control-icon-font-weight: #{$font-weight-bold};

    font-weight: var(--#{$prefix}carousel-control-icon-font-weight);
    font-size: var(--#{$prefix}carousel-control-icon-font-size);
    font-family: 'Font Awesome 6 Pro', 'Font Awesome 6 Free';
  }
}
.carousel-indicators {
  [data-mdb-target] {
    @extend [data-bs-target] !optional;
  }
}

// Additional MDB Angular styles
.carousel-indicators {
  button {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: $carousel-indicator-width;
    height: $carousel-indicator-height;
    padding: 0;
    margin-right: $carousel-indicator-spacer;
    margin-left: $carousel-indicator-spacer;
    text-indent: -999px;
    cursor: pointer;
    background-color: $carousel-indicator-active-bg;
    background-clip: padding-box;
    border: 0;
    // Use transparent borders to increase the hit area by 10px on top and bottom.
    border-top: $carousel-indicator-hit-area-height solid transparent;
    border-bottom: $carousel-indicator-hit-area-height solid transparent;
    opacity: $carousel-indicator-opacity;
    @include transition($carousel-indicator-transition);
  }
}
.carousel-dark {
  .carousel-indicators button {
    background-color: $carousel-dark-indicator-active-bg;
  }
}
// Additional MDB Angular styles
