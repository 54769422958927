//
// Modal styles
//

.modal-content {
  // scss-docs-start modal-css-vars
  --#{$prefix}modal-box-shadow: #{$modal-box-shadow};
  // scss-docs-end modal-css-vars

  border: 0;
  box-shadow: var(--#{$prefix}modal-box-shadow);
}

// Additional MDB Angular styles
.mdb-backdrop {
  background-color: rgba(0, 0, 0, 0.5);
  transition: opacity 0.15s linear;
}

.modal-dynamic > :first-child {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.modal-dialog-scrollable {
  .modal-content,
  .modal-content > :first-child {
    max-height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }

  .modal-body {
    overflow-y: auto;
  }
}
// Additional MDB Angular styles
