@mixin mdb-autocomplete-theme($theme) {
  $primary: map-get($theme, primary);
  $secondary: map-get($theme, secondary);
  $surface: map-get($theme, surface);
  $onSurface: map-get($theme, onSurface);
  $onPrimary: map-get($theme, onPrimary);
  $onSecondary: map-get($theme, onSecondary);
  $error: map-get($theme, error);
  $onError: map-get($theme, onError);
  $success: map-get($theme, success);
  $onSuccess: map-get($theme, onSuccess);
  $surfaceHover: map-get($theme, surfaceHover);
  $pickerHeader: map-get($theme, pickerHeader);
  $tooltipBackground: map-get($theme, tooltipBackground);
  $surfaceHover: map-get($theme, surfaceHover);
  $surfaceDisabled: map-get($theme, dropdownDisabled);

  .autocomplete-input.focused {
    color: $onSurface;

    & ~ .form-label {
      color: $primary;
    }

    & ~ .form-notch .form-notch-leading {
      border-color: $primary;
      box-shadow: -1px 0 0 0 $primary, 0 1px 0 0 $primary, 0 -1px 0 0 $primary;
    }

    & ~ .form-notch .form-notch-middle {
      border-color: $primary;
      box-shadow: 0 1px 0 0 $primary;
      border-top: 1px solid transparent;
    }

    & ~ .form-notch .form-notch-trailing {
      border-color: $primary;
      box-shadow: 1px 0 0 0 $primary, 0 -1px 0 0 $primary, 0 1px 0 0 $primary;
    }
  }

  .autocomplete-dropdown {
    background-color: $surface;
  }

  .autocomplete-items-list {
    &::-webkit-scrollbar-button {
      &:start:decrement,
      &:end:increment {
        background-color: $surface;
      }
    }

    &::-webkit-scrollbar-track-piece {
      background-color: $surface;
    }

    &::-webkit-scrollbar-thumb:vertical {
      background-color: $tooltipBackground;
    }
  }

  .autocomplete-item {
    color: $onSurface;
    background-color: transparent;

    &:hover:not(.disabled) {
      background-color: $surfaceHover;
    }

    &.active {
      background-color: $surfaceHover;
    }

    &.disabled {
      color: $surfaceDisabled;
    }
  }
}
