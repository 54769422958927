//
// Dropdown styles
//

.dropdown-menu {
  --#{$prefix}dropdown-item-border-radius: #{$dropdown-item-border-radius};

  color: var(--#{$prefix}dropdown-color);
  margin: 0;
  padding-top: 0;
  padding-bottom: 0;
  border: 0;
  box-shadow: var(--#{$prefix}dropdown-box-shadow);
  font-size: var(--#{$prefix}dropdown-font-size);
  // top: 100%;
  // left: 0;
  margin-top: var(--#{$prefix}dropdown-spacer);

  // Additional MDB Angular styles
  display: block;
  position: inherit;
  // Additional MDB Angular styles

  > li {
    border-radius: 0;
    &:first-child {
      border-top-left-radius: var(--#{$prefix}dropdown-item-border-radius);
      border-top-right-radius: var(--#{$prefix}dropdown-item-border-radius);
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      .dropdown-item {
        border-top-left-radius: var(--#{$prefix}dropdown-item-border-radius);
        border-top-right-radius: var(--#{$prefix}dropdown-item-border-radius);
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
    }

    &:not(:first-child):not(:last-child) {
      .dropdown-item {
        border-radius: 0;
      }
    }

    &:last-child {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      border-bottom-left-radius: var(--#{$prefix}dropdown-item-border-radius);
      border-bottom-right-radius: var(--#{$prefix}dropdown-item-border-radius);
      .dropdown-item {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        border-bottom-left-radius: var(--#{$prefix}dropdown-item-border-radius);
        border-bottom-right-radius: var(--#{$prefix}dropdown-item-border-radius);
      }
    }
  }

  // &.animation {
  //   --#{$prefix}dropdown-menu-animated-animation-duration: #{$dropdown-menu-animated-animation-duration};
  //   --#{$prefix}dropdown-menu-animated-animation-timing-function: #{$dropdown-menu-animated-animation-timing-function};

  //   display: block;
  //   /* Speed up animations */
  //   animation-duration: var(--#{$prefix}dropdown-menu-animated-animation-duration);
  //   animation-timing-function: var(--#{$prefix}dropdown-menu-animated-animation-timing-function);
  // }
}

.dropdown-item {
  --#{$prefix}dropdown-state-color: #{$dropdown-state-color};
  --#{$prefix}dropdown-state-background-color: #{$dropdown-state-background-color};

  padding: var(--#{$prefix}dropdown-item-padding-y) var(--#{$prefix}dropdown-item-padding-x);
  color: var(--#{$prefix}dropdown-color);
  border-radius: 0;

  &:hover,
  &:focus {
    color: var(--#{$prefix}dropdown-state-color);
    background-color: var(--#{$prefix}dropdown-state-background-color);
  }

  &.active,
  &:active {
    color: var(--#{$prefix}dropdown-state-color);
    background-color: var(--#{$prefix}dropdown-state-background-color);
  }
}

.hidden-arrow {
  &.dropdown-toggle:after {
    display: none;
  }
}

// .animation {
//   animation-duration: 1s;
//   animation-fill-mode: both;
//   padding: auto;
// }

// @media (prefers-reduced-motion) {
//   .animation {
//     transition: none !important;
//     animation: unset !important;
//   }
// }

// @keyframes fade-in {
//   from {
//     opacity: 0;
//   }

//   to {
//     opacity: 1;
//   }
// }

// .fade-in {
//   animation-name: fade-in;
// }

// @keyframes fade-out {
//   from {
//     opacity: 1;
//   }

//   to {
//     opacity: 0;
//   }
// }

// .fade-out {
//   animation-name: fade-out;
// }

.dropdown-divider {
  --#{$prefix}dropdown-divider-border-top-width: #{$dropdown-divider-border-top-width};
  --#{$prefix}dropdown-divider-border-top-bg: #{$dropdown-divider-bg};

  border-top: var(--#{$prefix}dropdown-divider-border-top-width) solid
    var(--#{$prefix}dropdown-divider-border-top-bg);
  opacity: 1;
}

// .dropdown-menu {
//   INPUT:not(:-webkit-autofill),
//   SELECT:not(:-webkit-autofill),
//   TEXTAREA:not(:-webkit-autofill) {
//     animation-name: none !important;
//   }
// }
