@mixin mdb-form-range-theme($theme) {
  $primary: map-get($theme, primary);
  $secondary: map-get($theme, secondary);
  $surface: map-get($theme, surface);
  $onSurface: map-get($theme, onSurface);
  $onPrimary: map-get($theme, onPrimary);
  $onSecondary: map-get($theme, onSecondary);
  $error: map-get($theme, error);
  $onError: map-get($theme, onError);
  $success: map-get($theme, success);
  $onSuccess: map-get($theme, onSuccess);
  $surfaceHover: map-get($theme, surfaceHover);
  $pickerHeader: map-get($theme, pickerHeader);

  .form-range {
    &::-webkit-slider-thumb {
      background-color: $primary;
    }
    &::-moz-range-thumb {
      background-color: $primary;
    }
    &::-ms-thumb {
      background-color: $primary;
    }

    &:focus {
      &::-webkit-slider-thumb {
        background-color: $primary;
      }
      &::-moz-range-thumb {
        background-color: $primary;
      }
      &::-ms-thumb {
        background-color: $primary;
      }
    }
  }

  // Additional MDB Angular styles
  .range .thumb::after {
    background: $primary;
  }
  // Additional MDB Angular styles
}
