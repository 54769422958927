:root{
  --verde-giacapp: #CFDC27;
  --rosso-giacapp: #d5193c;
  --blu-giacapp: #1FA6C0;

  --primary: #1FA6C0;
  --secondary: #CFDC27;
  --tertiarary:#d5193c;

  --backgrounds: white;
  --background-cards: white;
}
