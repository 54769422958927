@mixin mdb-form-control-theme($theme) {
  $primary: map-get($theme, primary);
  $secondary: map-get($theme, secondary);
  $surface: map-get($theme, surface);
  $onSurface: map-get($theme, onSurface);
  $onPrimary: map-get($theme, onPrimary);
  $onSecondary: map-get($theme, onSecondary);
  $error: map-get($theme, error);
  $onError: map-get($theme, onError);
  $success: map-get($theme, success);
  $onSuccess: map-get($theme, onSuccess);
  $surfaceHover: map-get($theme, surfaceHover);
  $pickerHeader: map-get($theme, pickerHeader);
  $inputBorder: map-get($theme, inputBorder);
  $inputLabel: map-get($theme, inputLabel);
  $inputText: map-get($theme, inputText);
  $inputPlaceholder: map-get($theme, inputPlaceholder);
  $inputReadonly: map-get($theme, inputReadonly);

  .form-label {
    color: $inputLabel;
  }

  .form-control {
    background-color: transparent;
    &:focus {
      background-color: transparent;
      color: $inputText;
    }
  }

  .form-control::placeholder {
    color: $inputPlaceholder;
  }

  .form-control {
    color: $inputText;
    &:focus {
      border-color: $primary;
      box-shadow: inset 0px 0px 0px 1px $primary;
    }
  }

  .form-outline {
    .form-control {
      background: transparent;
      color: $inputText;
      ~ .form-label {
        color: $inputLabel;
      }
      ~ .form-notch {
        div {
          border-color: $inputBorder;
          background: transparent;
        }
      }
      &:focus ~ .form-label {
        color: $primary;
      }
      &:focus ~ .form-notch .form-notch-middle {
        border-color: $primary;
        box-shadow: 0 1px 0 0 $primary;
        border-top: 1px solid transparent;
      }
      &:focus ~ .form-notch .form-notch-leading {
        border-color: $primary;
        box-shadow: -1px 0 0 0 $primary, 0 1px 0 0 $primary, 0 -1px 0 0 $primary;
      }
      &:focus ~ .form-notch .form-notch-trailing {
        border-color: $primary;
        box-shadow: 1px 0 0 0 $primary, 0 -1px 0 0 $primary, 0 1px 0 0 $primary;
      }
      &:disabled,
      &.disabled,
      &[readonly]:not(.select-input) {
        background-color: $inputReadonly;
      }
    }
  }

  .select.focused {
    & ~ .form-notch .form-notch-leading {
      border-color: $primary;
      box-shadow: -1px 0 0 0 $primary, 0 1px 0 0 $primary, 0 -1px 0 0 $primary;
    }

    & ~ .form-notch .form-notch-middle {
      border-color: $primary;
      box-shadow: 0 1px 0 0 $primary;
      border-top: 1px solid transparent;
    }

    & ~ .form-notch .form-notch-trailing {
      border-color: $primary;
      box-shadow: 1px 0 0 0 $primary, 0 -1px 0 0 $primary, 0 1px 0 0 $primary;
    }
  }
}
