/* You can add global styles to this file, and also import other style files */
@import '@fortawesome/fontawesome-free/css/all.css';
@import 'mdb-angular-ui-kit/assets/scss/mdb.scss';

@import 'theme/variabless';

/* Impedisce la selezione per tutti gli elementi */
* {
  -webkit-user-select: none; /* Chrome, Safari, Opera */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Standard */
}

html {
  font-size: 16px;
}

html, body {
  overscroll-behavior-y: contain;
}

body {
  font-family: 'Catamaran', sans-serif;
  color: black!important;
  margin: 0;
  padding: 0;
}

.clickable{
  cursor: pointer;
}

.loading-position {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.error-div {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  font-size: 1.2rem;
}

.tasto-back{
  position: absolute;
  bottom: 50px;
  left: 50px;
  button {
    background-color: var(--primary);
    color: white;
    font-size: 2rem;
    min-width: 0;
    //padding: 4px 5px 7px 5px;
    padding: 0;
    height: 3rem;
    width: 3rem;
  }
}

.tasto-avanti{
  position: absolute;
  bottom: 50px;
  right: 50px;
  button {
    background-color: white;
    color: black;
    font-size: 2rem;
    min-width: 0;
    position: relative;
    height: 3rem;
    width: 3rem;
    padding: 0;
  }
}

.center-icon{
  position: absolute;
  top: 50%;
  width: 50%;
  transform: translate(-50%, -50%);
}

qrcode {
  display: flex;
  justify-content: center;
  padding-top: 26px;
}

.qr-code {
  height: fit-content!important;
  width: fit-content!important;
}

mdb-select > input {
  overflow: hidden;
  text-overflow: ellipsis;
}

mdb-select {
  position: relative;
}

mdb-select > .select-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
  height: 100%;
  display: flex;
  align-items: center;
}

mdb-option > .option-text {
  overflow: hidden;
  text-overflow: ellipsis;
}

.loading {
  color: var(--primary);
}

.btn-primary {
  border-radius: 100px;
}

.header {
  position: absolute;
  top: 20px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 30px;
  z-index: 1000;
}

.logo {
  height: 100px;
}

.form-check-input[type=radio]{
  background: white !important;
  height: 1.8rem !important;
  border-color: var(--primary) !important;
  width: 1.8rem;
  border-radius: 100% !important;
}

.form-check-input[type=radio]:checked:after{
  border-color: var(--primary)!important;
  background-color: var(--rosso-giacapp)!important;
}

.form-check-input:checked {
  border-color: var(--primary)!important;
}

.form-check-input:checked:focus:before{
  box-shadow: none!important;
}

.modal-content {
  background-color: var(--background-cards)!important;
  color: black;
}

.background-color-custom {
  background-color: var(--background-cards)!important;
}

.card-header .background-color-custom {
  font-size: 1.4rem!important;
}

.card-body .background-color-custom{
  font-size: 1.2rem!important;
}

.progress-color {
  background-color: var(--verde-giacapp);
}

.progress-bar-custom {
  background-color: var(--primary)!important;
  color: white;
}

.photo-booth-container{
  background: var(--backgrounds);
  height: 100vh;
}

.btn {
  padding: 0.85rem 1rem 0.75rem;
  text-transform: unset;
  box-shadow: unset !important;
  border-radius: 100px;
  font-size: 1rem;
  min-width: 200px;
}

.modal-header {
  font-size: 1.4rem;
  padding-left: 40px;
  padding-right: 40px;
  border-bottom: none!important;
}

.modal-body {
  font-size: 1.2rem;
  color: #4c535f;
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 40px;
  border: none!important;
}

input {
  height: 50px!important;
  border-radius: 10px!important;
  font-size: 1.4rem!important;
  background: #edf2f6!important;
}

.select-input {
  height: 50px!important;
  border-radius: 10px!important;
  font-size: 1.4rem!important;
  background: #edf2f6!important;
}

.form-outline{
  height: 50px!important;
  border-radius: 10px!important;
  font-size: 1.4rem!important;
  background: #edf2f6!important;
}

.modal-code {
  min-width: 650px!important;
}

.modal-qr-code {
  min-width: 450px!important;
}

.modal-alert {
  min-width: 650px!important;
}

.option{
  font-size: 1.4rem!important;
  color: #4c535f!important;
}

.select-arrow{
  top: 50%;
  transform: translateY(-50%);
}

.footer-buttons {
  display: flex;
  justify-content: center;
  border: none!important;
}

.card-footer {
  border: none!important;
}

@media (max-height: 1400px) {
  .logo{
    height: 55px;
  }
}

@media (min-width: 1400px) {
  .logo{
    height: 100px;
  }
}

// iPad Air
@media (max-height: 1000px) {

  .error-div {
    font-size: 1.2rem;
  }

  .tasto-back{
    bottom: 50px;
    left: 50px;
    button {
      font-size: 1.6rem;
      padding: 0;
      height: 2.5rem;
      width: 2.5rem;
    }
  }

  .tasto-avanti{
    bottom: 50px;
    right: 50px;
    button {
      font-size: 1.6rem;
      padding: 0;
      height: 2.5rem;
      width: 2.5rem;
    }
  }

  .card-header .background-color-custom {
    font-size: 1.4rem!important;
  }

  .card-body .background-color-custom{
    font-size: 1.2rem!important;
  }

  .modal-header {
    font-size: 1.4rem;
  }

  .modal-body {
    font-size: 1.2rem;
  }

  input {
    font-size: 1.2rem!important;
  }

  .select-input {
    font-size: 1.2rem!important;
  }

  .form-outline{
    font-size: 1.2rem!important;
  }

  .option{
    font-size: 1.2rem!important;
  }

  .form-check-input[type=radio]{
    height: 1.6rem !important;
    width: 1.6rem;
  }

  qrcode {
    padding-top: 18px!important;
  }

}

// iPad Mini
@media (max-height: 800px) {

  .logo{
    height: 40px;
  }

  .error-div {
    font-size: 1.2rem;
  }

  .tasto-back{
    bottom: 20px;
    left: 20px;
    button {
      font-size: 1.4rem;
      padding: 0;
      height: 2.5rem;
      width: 2.5rem;
    }
  }

  .tasto-avanti{
    bottom: 20px;
    right: 20px;
    button {
      font-size: 1.4rem;
      padding: 0;
      height: 2.5rem;
      width: 2.5rem;
    }
  }

  .card-header .background-color-custom {
    font-size: 1.4rem!important;
  }

  .card-body .background-color-custom{
    font-size: 1.2rem!important;
  }

  .modal-header {
    font-size: 1.4rem;
  }

  .modal-body {
    font-size: 1.2rem;
  }

  input {
    font-size: 1rem!important;
  }

  .select-input {
    font-size: 1rem!important;
  }

  .form-outline{
    font-size: 1rem!important;
  }

  .option{
    font-size: 1rem!important;
  }

  .form-check-input[type=radio]{
    height: 1.4rem !important;
    width: 1.4rem;
  }

  qrcode {
    padding-top: 18px!important;
  }

}

@media (orientation: portrait) {

  .header {
    padding: 0 10px;
  }

  .btn {
    min-width: 126px;
  }

}

@media (max-width: 768px) {
  .logo{
    height: 40px;
  }

  .modal-dialog{
    max-width: calc(100vw - 15px)!important;
    margin-left: 20px!important;
    margin-right: 20px!important;
  }
}

@media (max-width: 500px) {

  .header {
    top: 10px;
  }

  .btn {
    font-size: 1rem;
  }

  .modal-alert {
    min-width: unset!important;
  }

  .modal-header {
    font-size: 1.4rem;
    padding-left: 20px;
    padding-right: 20px;
    border-bottom: none!important;
  }

  .modal-body {
    font-size: 1.2rem;
    color: #4c535f;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
    border: none!important;
  }

  .tasto-back{
    left: 20px;
    button {
      font-size: 1.4rem;
    }
  }

  .tasto-avanti{
    right: 20px;
    button {
      font-size: 1.4rem;
    }
  }
}

.modal-dialog.modal-fullscreen{
  width: 100vw!important;
  max-width: none!important;
  height: 100%!important;
  margin: 0!important;
  position: absolute!important;
  top: 0!important;
  left: 0!important;
}

// Personalizzazioni colore
$my-theme-primary: #1fa6c0;
$my-theme-secondary: #CFDC27;
$my-theme-tertiary: #d5193c;

$my-dark-theme: mdb-light-theme($my-theme-primary, $my-theme-secondary); // create the new dark theme using primary and secondary colors
//// include theme styles
@include mdb-theme($my-dark-theme);
