@mixin mdb-datatables-theme($theme) {
  $primary: map-get($theme, primary);
  $secondary: map-get($theme, secondary);
  $surface: map-get($theme, surface);
  $onSurface: map-get($theme, onSurface);
  $onPrimary: map-get($theme, onPrimary);
  $onSecondary: map-get($theme, onSecondary);
  $error: map-get($theme, error);
  $onError: map-get($theme, onError);
  $success: map-get($theme, success);
  $onSuccess: map-get($theme, onSuccess);
  $surfaceHover: map-get($theme, surfaceHover);
  $pickerHeader: map-get($theme, pickerHeader);
  $divider: map-get($theme, divider);

  .datatable {
    background: $surface;
    color: $onSurface;

    .table {
      border-color: $divider;
    }

    .table > :not(caption) > * > * {
      background-color: $surface;
      border-color: $divider;
    }

    table {
      color: $onSurface;
      border-color: $divider;

      th,
      td {
        border-color: $divider;
      }
    }

    thead {
      tr {
        border-bottom-color: $divider;
      }

      .fixed-cell {
        box-shadow: hsla(0, 0%, 100%, 0.12) 0px 1px;
        background-color: $surface;
      }
    }

    tbody {
      tr {
        &.active {
          background-color: $onSurface;
        }
      }
    }

    &-pagination {
      border-top-color: $divider;
    }

    &-pagination-button {
      color: $onSurface;
    }

    &-sort-icon {
      color: $onSurface;

      &.active {
        color: $onSurface;
      }
    }

    &.datatable-bordered {
      .datatable-pagination {
        border-color: $divider;
      }
    }

    &.datatable-striped {
      tbody {
        tr:nth-of-type(odd) {
          color: $onSurface;
        }
      }
    }

    &.datatable-borderless {
      border-color: transparent;
    }

    &.datatable-loading {
      color: $onSurface;

      th {
        color: $onSurface;
      }
    }
  }
}
